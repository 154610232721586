import React, { useEffect, useState } from 'react'
import Heading from '../../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../../components/common-components/heading/Constants'
import { ABDM_PROFILES, ABHA_HEALTH_RECORD_NAVS } from '../Constants';
import DiagnosticRecord from '../Records/DiagnosticRecord';
import PrescriptionRecord from '../Records/PrescriptionRecord';
import { setBundlePushError, setBundlePushResponse, setSelectedBundle } from '../../PatientDashboardSlice';
import { useDispatch } from 'react-redux';
import DischargeSummary from '../Records/DischargeSummary';
import HealthDocumentRecord from '../Records/HealthDocumentRecord';
import OpConsultation from '../Records/OpConsultationRecord';
import WellnessRecord from '../Records/WellnessRecord';
import ImmunizationRecord from '../Records/ImmunizationRecord';
import InvoiceRecord from '../Records/InvoiceRecord';
import Icons from '../../../../../assets/svgs';
import Label from '../../../../../components/common-components/label/Label';
import Input from '../../../../../components/common-components/input/Input';

function AddABHAhealthRecord({handleABHAhealthRecord}) {
    const [selectedType, setSelectedType] = useState(ABDM_PROFILES[0].value)
    const [displayName, setDisplayName] = useState("");
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(setSelectedBundle(null))
            dispatch(setBundlePushResponse({}))
            dispatch(setBundlePushError({}))
        }
    }, [dispatch])

    const payloadHelper = {
        displayName,
        hiType: selectedType,
    }

    return (
        <div className='flex flex-col w-full h-full gap-4 px-2 py-1'>
            <div className='flex flex-col gap-4'>
                <Heading fontSize="2xl" fontWeight="bold" type={HEADING.H1}>Add ABHA Health Record</Heading>
                <div className='flex gap-4 flex-wrap'>
                    {ABDM_PROFILES.map((item, index) => (
                        <div 
                            key={item.title + index} 
                            className={`border border-gray-200 px-2 py-1 flex gap-4 items-center rounded-md hover:cursor-pointer 
                                shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out transform hover:scale-105
                                ${selectedType === item.value ? "border-2 border-met-primary bg-met-secondary" : ""}`}
                            onClick={() => {
                                setDisplayName("");
                                setSelectedType(item.value);
                            }}
                        >
                            <Icons iconName={item.icon} height={"35px"} width={"35px"} />
                            <Label fontSize={"25px"} fontWeight="bold" customClass="hover:cursor-pointer">
                                {item.title}
                            </Label>
                        </div>
                    
                    ))}
                </div>
            </div>
            <div className='z-50 w-full h-full border-2 rounded-md border-gray-200 py-2 px-4 flex flex-col gap-2 justify-between'>
                <Input
                    label="Record title"
                    placeholder="Please enter record title"
                    value={displayName}
                    isRequired={true}
                    onChangeCb={(e) => setDisplayName(e.target.value)}
                />
                {selectedType === ABHA_HEALTH_RECORD_NAVS.DIAGNOSTIC_REPORT &&
                    <DiagnosticRecord 
                        handleCancle={handleABHAhealthRecord} 
                        payloadHelper={payloadHelper}
                    />
                }
                {selectedType === ABHA_HEALTH_RECORD_NAVS.DISCHARGE_SUMMARY &&
                    <DischargeSummary 
                        handleCancle={handleABHAhealthRecord} 
                        payloadHelper={payloadHelper}
                    />
                }
                {selectedType === ABHA_HEALTH_RECORD_NAVS.HEALTH_DOCUMENT_RECORD &&
                    <HealthDocumentRecord 
                        handleCancle={handleABHAhealthRecord} 
                        payloadHelper={payloadHelper}
                    />
                }
                {selectedType === ABHA_HEALTH_RECORD_NAVS.IMMUNIZATION_RECORD &&
                    <ImmunizationRecord 
                        handleCancle={handleABHAhealthRecord} 
                        payloadHelper={payloadHelper}
                    />
                }
                {selectedType === ABHA_HEALTH_RECORD_NAVS.OP_CONSULTATION &&
                    <OpConsultation 
                        handleCancle={handleABHAhealthRecord} 
                        payloadHelper={payloadHelper}
                    />
                }
                {selectedType === ABHA_HEALTH_RECORD_NAVS.PRESCRIPTION &&
                    <PrescriptionRecord 
                        handleCancle={handleABHAhealthRecord} 
                        payloadHelper={payloadHelper}
                    />
                }
                {selectedType === ABHA_HEALTH_RECORD_NAVS.WELLNESS_RECORD &&
                    <WellnessRecord 
                        handleCancle={handleABHAhealthRecord} 
                        payloadHelper={payloadHelper}
                    />
                }
                {selectedType === ABHA_HEALTH_RECORD_NAVS.INVOICE &&
                    <InvoiceRecord 
                        handleCancle={handleABHAhealthRecord} 
                        payloadHelper={payloadHelper}
                    />
                }
            </div>
        </div>
    )
}

export default AddABHAhealthRecord;