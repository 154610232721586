import React, { useEffect, useState } from "react";
import Input from "../../../../../components/common-components/input/Input";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import { ABHA_HEALTH_RECORD_FIELDS, ABHA_HEALTH_RECORD_HI_TYPES, ABHA_HEALTH_RECORD_NAVS, DIAGNOSTIC_REPORT_lAB_STATUS, RECORD_STATUS, RECORD_TYPES } from "../Constants";
import { Formik } from "formik";
import TextArea from "../../../../../components/common-components/textArea/TextArea";
import ItemManager from "../ItemManager";
import Modal from "../Modal";
import Label from "../../../../../components/common-components/label/Label";
import Observation from "../Records/observation";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import { v4 as uuidv4 } from 'uuid';
import Icons from "../../../../../components/icons/Icons";

const DiagnosticLabReport = ({onConfirm, close}) => {
    const [specimenList, setSpecimenList] = useState([]);
    const [observationList, setObservationList] = useState([]);
    const [isSpecimenPopupOpen, setIsSpecimenPopupOpen] = useState(false);
    const [isObservationPopupOpen, setIsObservationPopupOpen] = useState(false);
    const [observationError, setObservationError] = useState("")

    const handleAddItem = (values, setFieldValue) => {

        if (!values.specimen || !values.specimenTime) return;
    
        const newSpecimen = {
            type: values.specimen.trim(),
            receivedTime: values.specimenTime,
        };
    
        setSpecimenList((prev) => [...prev, newSpecimen]);
    
        setFieldValue("specimen", "");
        setFieldValue("specimenTime", "");
    
        setIsSpecimenPopupOpen(false);
    };
    
    const validate = (values) => {
        const errors = {};
        if (values.labReportStatus.value === "Select Status") {
            errors.labReportStatus = "Status is required.";
        }
        if (observationList.length === 0) {
            setObservationError("At least one observation/result must be provided.")
        }
        if (!values.conclusion) {
            errors.conclusion ="Conclusion is required.";
        }
        if (isSpecimenPopupOpen) {
            if (!values.specimen) {
                errors.specimen = "Specimen is required.";
            }
            if (!values.specimenTime) {
                errors.specimenTime = "Specimen time is required.";
            }
        }
        return errors;
    };

    return (
        <>
        <Formik
            initialValues={{
                recordType: { label: "Select Record Type", value: "Select Record Type" },
                labReportStatus: { label: "Select Status", value: "Select Status" },
                conclusion:""

            }}
            validate={validate}
            onSubmit={(values) => {
                if(observationList.length === 0) {
                    return;
                }
                const data = {
                    resourceType: values.recordType.value,
                    status: values.labReportStatus.value,
                    ...(specimenList.length > 0 ? {specimen: specimenList}:{}),
                    result: observationList.map((item) => item.data),
                    conclusion: values.conclusion
                }
                onConfirm((prev) => [...prev, {type: `Report ${prev.length + 1}`, labReport: data}])
                close()
            }}
        >
            {({ values, errors, touched, setFieldValue, handleSubmit, setFieldTouched }) => (
                <>
                    <div className="fixed h-full inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50 ">
                        <div className="flex flex-col gap-4 bg-white shadow-lg rounded-lg p-6 w-[90%] max-w-5xl min-h-[35%] max-h-[90%] overflow-y-auto">
                            <div className="flex justify-between items-center border-b pb-3">
                                <h2 className="text-lg font-semibold">Add Diagnostic Lab Report</h2>
                                <div className="flex justify-end items-center-b pb-3 hover:cursor-pointer" onClick={close}>
                                    <Icons iconName={"closeIcon"}/>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit} className="flex-1 w-full flex flex-col justify-between gap-4">
                                <div className="grid grid-cols-2 gap-2">                                    
                                    <SelectDropdown
                                        label={<>Select Status <span className="text-red-500">*</span></>} 
                                        name="labReportStatus"
                                        customClasses="w-full"
                                        value={values.labReportStatus}
                                        options={[{ label: "Select Status", value: "Select Status" }, ...DIAGNOSTIC_REPORT_lAB_STATUS]}
                                        onChangeCb={(selectedOption) =>
                                            setFieldValue("labReportStatus", selectedOption)
                                        }
                                    />
                                    <ItemManager
                                        key={`Specimens_${uuidv4()}`}
                                        title={"Specimens"}
                                        items={specimenList}
                                        setItems={setSpecimenList}
                                        setIsPopupOpen={setIsSpecimenPopupOpen}
                                        customHeight="44"
                                        customClass={"col-span-2"}
                                    />
                                    <div className="mt-2 col-span-2">
                                        <ItemManager
                                            key={`observation_${uuidv4()}`}
                                            title={<>Result/Observation <span className="text-red-500">*</span></>} 
                                            items={observationList}
                                            setItems={setObservationList}
                                            setIsPopupOpen={setIsObservationPopupOpen}
                                            customHeight="44"
                                        />
                                        {observationError && (
                                            <div className="text-red-500 text-sm">{observationError}</div>
                                        )}
                                    </div>
                                    <div className="mt-2 col-span-2">
                                        <TextArea
                                            label="Conclusion"
                                            placeholder="Enter conclusion..."
                                            name={"conclusion"}
                                            value={values.conclusion}
                                            isRequired={true}
                                            onChangeCb={(e) => { 
                                                setFieldValue("conclusion", e.target.value) 
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-end">
                                    <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                        Submit
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <Modal
                        title="Add Specimen"
                        isOpen={isSpecimenPopupOpen}
                        onClose={() => setIsSpecimenPopupOpen(false)}
                        onConfirm={() => {
                            let errors = {};

                            if (!values.specimen) {
                                errors.specimen = "Specimen is required.";
                            }
                            if (!values.specimenTime) {
                                errors.specimenTime = "Specimen time is required.";
                            }

                            if (Object.keys(errors).length > 0) {
                                setFieldTouched("specimen", true);
                                setFieldTouched("specimenTime", true);
                                return;
                            }

                            handleAddItem(values, setFieldValue);
                        }}
                    >
                        <div className="flex flex-col gap-4">
                            <Input
                                label="Specimen"
                                name="specimen"
                                isRequired={true}
                                placeholder="Enter kind of material that forms the specimen Ex: Serum"
                                value={values.specimen}
                                onChangeCb={(e) => {
                                    setFieldValue("specimen", e.target.value);
                                }}
                            />

                            <Input
                                label="Select Specimen Date and Time"
                                name="specimenTime"
                                placeholder="Enter received date time"
                                type="datetime-local"
                                value={values.specimenTime}
                                isRequired={true}
                                onChangeCb={(e) => {
                                    setFieldValue("specimenTime", e.target.value);
                                }}
                            />
                        </div>
                    </Modal>

                </>
            )}
        </Formik>
        {isObservationPopupOpen && 
            <Observation 
                key={`labReport_${uuidv4()}`}
                onConfirm={setObservationList} 
                setObservationError={setObservationError}
                close={()=> setIsObservationPopupOpen(false)}
                isNested= {true}
            />
        }
        </>
    );
};

export default DiagnosticLabReport;
