import React, { useEffect, useState } from "react";
import Input from "../../../../../components/common-components/input/Input";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import {  RECORD_STATUS } from "../Constants";
import { Formik } from "formik";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ItemManager from "../ItemManager";
import { componentKey as PatientDashboardComponentKey, setBundlePushError, setBundlePushResponse, setSelectedBundle } from "../../PatientDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { createPrescriptionBundle, pushBundle, retryDataPush } from "../../PatientDashboardSaga";
import Label from "../../../../../components/common-components/label/Label";
import HealthRecordHelper from "../utils/utils";
import MedicationRequest from "./MedicationRequest";
import LoadingPopup from "../LoadingPopup";

const PrescriptionRecord = ({ handleCancle, payloadHelper}) => {
  const { patientData, selectedBundle, bundlepushError } = useSelector((state) => state[PatientDashboardComponentKey]);
  const [reportsList, setReportsList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [reportsError, setReportsError] = useState("");
  const [loadingpopup, setLoadingPopup] = useState(false);
  const [isLinkingFailed, setIsLinkingFailed] = useState(false);

  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};
    if (!values.compositionStatus || values.compositionStatus.value === "Select Status") {
      errors.compositionStatus = "Please select composition status.";
    }
    if (!values.prescriptionDate) {
      errors.prescriptionDate = "Please provide a date and time.";
    }
    if (!values.prescriptionTitle) {
      errors.prescriptionTitle = "Please provide a title.";
    }
    return errors;
  };

  useEffect(() => {
    if(reportsList.length > 0) setReportsError("");
  },[reportsList])

  useEffect(() => {
    if(selectedBundle){
      const payload = {
        ...payloadHelper,
        patientId: patientData?.uuid,
        bundleData: selectedBundle,
      }
      if(isLinkingFailed) {
        payload.previous = true;
        dispatch(retryDataPush(payload))
      } else {
        dispatch(pushBundle(payload));
      }
      setLoadingPopup(true)
    }
  },[dispatch, selectedBundle])
  
  useEffect(() => {
    if(bundlepushError?.statusCode){
      setLoadingPopup(false);
      setIsLinkingFailed(true)
      dispatch(setBundlePushError({}))
    }
  }, [bundlepushError, dispatch])

  useEffect(() => {
      return () => {
          dispatch(setSelectedBundle(null))
          dispatch(setBundlePushResponse({}))
          dispatch(setBundlePushError({}))
      }
  }, [dispatch])

  const structurePayloadWithAllData = (values) => {
      const payload = {
        status: values.compositionStatus.value,
        date: values.prescriptionDate,
        title: values.prescriptionTitle,
        subject: HealthRecordHelper.getStructurePatientData(patientData),
        entry: reportsList?.map((report) => report.medicationRequestReport),
      };
      return payload;
  };

  return (
      <>
          <Formik
              initialValues={{
                  compositionStatus:{ label: "Select Status", value: "Select Status" },
                  recordType: { label: "Select Record Type", value: "Select Record Type" },
                  prescriptionDate:"",
                  prescriptionTitle:"",
              }}
              validate={validate}
              onSubmit={(values) => {
                dispatch(setSelectedBundle(null));
                if(reportsList.length === 0) {
                  setReportsError("Atleast 1 report is required.");
                  return;
                }
                const payload = structurePayloadWithAllData(values);
                dispatch(createPrescriptionBundle(payload))
              }}
          >
              {({ values, errors, setFieldValue, handleSubmit }) => (
                  <form onSubmit={handleSubmit} className="space-y-4 h-full w-full flex flex-col justify-between">
                      <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                          <SelectDropdown
                              label="Composition Status"
                              name="compositionStatus"
                              isRequired={true}
                              customClasses="w-full"
                              value={values.compositionStatus}
                              options={[{ label: "Select Status", value: "Select Status" }, ...RECORD_STATUS]}
                              onChangeCb={(selectedOption) =>
                                  setFieldValue("compositionStatus", selectedOption)
                              }
                          />
                          <Input
                            label="Prescription Editing Time"
                            name="prescriptionDate"
                            type="datetime-local"
                            isRequired={true}
                            value={values.prescriptionDate}
                            onChangeCb={(e) => setFieldValue("prescriptionDate", e.target.value)}
                          />
                          <Input
                            label="Title"
                            placeholder="Human Readable name/title"
                            type="text"
                            name="prescriptionTitle"
                            isRequired={true}
                            value={values.prescriptionTitle}
                            onChangeCb={(e) => setFieldValue("prescriptionTitle", e.target.value)}
                          />
                          
                          <div className="flex flex-col gap-2 col-span-2 sm:col-span-3">
                            <ItemManager
                                title={<>Report entry <span className="text-red-500">*</span></>} 
                                items={reportsList}
                                setItems={setReportsList}
                                setIsPopupOpen={setIsPopupOpen}
                                customHeight="52"
                            />
                            {reportsError && <Label fontSize="sm" fontWeight="bold" color="red-500">{reportsError}</Label>}
                          </div>
                          
                      </div>
                      <div className="flex justify-end gap-2">
                        <Button 
                            onClickCb={handleCancle} 
                            variant={BUTTON_VARIANTS.OUTLINED}
                        >Cancel</Button>
                          <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                            {isLinkingFailed ? "Try Again" : "Link to ABDM"}
                          </Button>
                      </div>
                  </form>
              )}
          </Formik>
          {isPopupOpen && <MedicationRequest onConfirm={setReportsList} close={() => setIsPopupOpen(false)}/>}
          <LoadingPopup
              isOpen={loadingpopup} 
              handleCancle = {() => {
                setLoadingPopup(false)
                handleCancle()
              }}
              handleClose = {() => setLoadingPopup(false)}
          />
      </>
  );
};

export default PrescriptionRecord;
