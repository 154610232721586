import React, { useEffect, useState } from "react";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import { RECORD_STATUS, FORM_FIELDS_FOR_WELLNESS } from "../Constants";
import { Formik } from "formik";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ItemManager from "../ItemManager";
import { componentKey as PatientDashboardComponentKey, setBundlePushError, setBundlePushResponse, setSelectedBundle } from "../../PatientDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import Observation from "./observation";
import DocumentReference from "./DocumentReference";
import Input from "../../../../../components/common-components/input/Input";
import { getValidationSchema } from "../../../../../libs/formsUtils";
import { createWellnessBundle, pushBundle, retryDataPush } from "../../PatientDashboardSaga";
import OtherObservation from "./OtherObservation";
import Label from "../../../../../components/common-components/label/Label";
import HealthRecordHelper from "../utils/utils";
import LoadingPopup from "../LoadingPopup";

const fields = [
	{ fieldName: FORM_FIELDS_FOR_WELLNESS.STATUS, isRequired: true, isDropdown: true },
    { fieldName: FORM_FIELDS_FOR_WELLNESS.TITLE, isRequired: true },
    { fieldName: FORM_FIELDS_FOR_WELLNESS.DATE, isRequired: true },
];

const validationSchema = getValidationSchema(fields);

const WellnessRecord = ({ handleCancle, payloadHelper }) => {
    const { patientData, selectedBundle, bundlepushError } = useSelector(
        (state) => state[PatientDashboardComponentKey]
    );
    const [vitalSignsList, setVitalSignsList] = useState([]);
    const [bodyMeasurementList, setBodyMeasurementList] = useState([]);
    const [physicalActivityList, setPhysicalActivityList] = useState([]);
    const [generalAssessmentList, setGeneralAssesmentList] = useState([]);
    const [womenHealthList, setWomenHealthList] = useState([]);
    const [lifestyleList, setLifestyleList] = useState([]);
    const [otherObservationList, setOtherObservationList] = useState([]);
    const [documentReferenceList, setDocumentReferenceList] = useState([]);

    const [isVitalSignsPopupOpen, setIsVitalSignsPopupOpen] = useState(false);
    const [isBodyMeasurementPopupOpen, setIsBodyMeasurementPopupOpen] = useState(false);
    const [isPhysicalActivityPopupOpen, setIsPhysicalActivityPopupOpen] = useState(false);
    const [isGeneralAssesmentPopupOpen, setIsGeneralAssesmentPopupOpen] = useState(false);
    const [isWomenHealthPopupOpen, setIsWomenHealthPopupOpen] = useState(false);
    const [isLifestylePopupOpen, setIsLifestylePopupOpen] = useState(false);
    const [isOtherObservationPopupOpen, setIsOtherObservationPopupOpen] = useState(false);
    const [isDocumentReferencePopupOpen, setIsDocumentReferencePopupOpen] = useState(false);
    const [loadingpopup, setLoadingPopup] = useState(false);
    const [isLinkingFailed, setIsLinkingFailed] = useState(false);
    const [sectionError, setSectionError]= useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        if(selectedBundle){
            const payload = {
                ...payloadHelper,
                patientId: patientData?.uuid,
                bundleData: selectedBundle,
            }
            if(isLinkingFailed) {
                payload.previous = true;
                dispatch(retryDataPush(payload))
            } else {
                dispatch(pushBundle(payload));
            }
            setLoadingPopup(true)
        }
    },[dispatch, selectedBundle])

    useEffect(() => {
    if(bundlepushError?.statusCode){
        setLoadingPopup(false);
        setIsLinkingFailed(true)
        dispatch(setBundlePushError({}))
    }
    }, [bundlepushError, dispatch])

    useEffect(() => {
        return () => {
            dispatch(setSelectedBundle(null))
            dispatch(setBundlePushResponse({}))
            dispatch(setBundlePushError({}))
        }
    }, [dispatch])

    useEffect(() => {
        if(vitalSignsList.length > 0 || bodyMeasurementList.length > 0 || physicalActivityList.length > 0 || generalAssessmentList.length > 0 || womenHealthList.length > 0 || lifestyleList.length > 0 || otherObservationList.length > 0 || documentReferenceList.length > 0){
            setSectionError("");
        }
    }, [vitalSignsList, bodyMeasurementList, physicalActivityList, generalAssessmentList, womenHealthList, lifestyleList, otherObservationList, documentReferenceList])

    const structurePayloadWithAllData = (values) => {
        const payload = {
            title: values[FORM_FIELDS_FOR_WELLNESS.TITLE],
            status: values[FORM_FIELDS_FOR_WELLNESS.STATUS].value,
            date: values[FORM_FIELDS_FOR_WELLNESS.DATE],
            subject: HealthRecordHelper.getStructurePatientData(patientData),
            section: [
                vitalSignsList.length > 0 ? {
                    title:"Vital Signs",
                    entry: vitalSignsList.map((item) => item.data),
                } : null,
                bodyMeasurementList.length > 0 ? {
                    title:"Body Measurement",
                    entry: bodyMeasurementList.map((item) => item.data),
                } : null,
                physicalActivityList.length > 0 ? {
                    title:"Physical Activity",
                    entry: physicalActivityList.map((item) => item.data),
                } : null,
                generalAssessmentList.length > 0 ? {
                    title:"General Assessment",
                    entry: generalAssessmentList.map((item) => item.data),
                } : null,
                womenHealthList.length > 0 ? {
                    title:"Women Health",
                    entry: womenHealthList.map((item) => item.data),
                } : null,
                lifestyleList.length > 0 ? {
                    title:"Lifestyle",
                    entry: lifestyleList.map((item) => item.data),
                } : null,
                otherObservationList.length > 0 ? {
                    title:"Other Observations",
                    entry: otherObservationList.map((item) => item.data),
                } : null,
                documentReferenceList.length > 0 ? {
                    title:"Document Reference",
                    entry: documentReferenceList.map((item) => item.data),
                } : null,

            ].filter(Boolean),
        };
        return payload;
    };

    return (
        <>
        <Formik
            initialValues={{
                [FORM_FIELDS_FOR_WELLNESS.STATUS]: "",
                [FORM_FIELDS_FOR_WELLNESS.DATE]:"",
                [FORM_FIELDS_FOR_WELLNESS.TITLE]:"",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                dispatch(setSelectedBundle(null));
                const payload = structurePayloadWithAllData(values);
                if(payload.section.length <= 0) {
                    setSectionError("Atleast 1 section is required")
                    return;
                }
                dispatch(createWellnessBundle(payload));
            }}
        >
            {({ values, errors, setFieldValue, handleSubmit }) => (
            <form
                onSubmit={handleSubmit}
                className="w-full flex flex-col gap-4 justify-between"
            >
                <div className="h-full w-full grid grid-cols-1 sm:grid-cols-3 gap-2 ">
                    <SelectDropdown
                        label="Composition Status"
                        name={FORM_FIELDS_FOR_WELLNESS.STATUS}
                        isRequired={true}
                        placeholder="Select"
                        value={values[FORM_FIELDS_FOR_WELLNESS.STATUS]}
                        options={RECORD_STATUS}
                        onChangeCb={(selectedOption) =>
                            setFieldValue(FORM_FIELDS_FOR_WELLNESS.STATUS, selectedOption)
                        }
                    />
                    <Input
                        label="Title"
                        placeholder="Enter the Title"
                        isRequired={true}
                        name={FORM_FIELDS_FOR_WELLNESS.TITLE}
                        value={values[FORM_FIELDS_FOR_WELLNESS.TITLE]}
                        onChangeCb={(e) => {
                            setFieldValue(FORM_FIELDS_FOR_WELLNESS.TITLE, e.target.value)
                        }}
                    />

                    <Input
                        label="Date"
                        name={FORM_FIELDS_FOR_WELLNESS.DATE}
                        type="datetime-local"
                        isRequired={true}
                        value={values[FORM_FIELDS_FOR_WELLNESS.DATE]}
                        onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_WELLNESS.DATE, e.target.value)}
                    />
                    <div className="sm:col-span-3">
                        <div className="border border-gray-200 rounded-md max-h-[32vh] overflow-y-auto px-4 py-2 flex flex-col gap-2">
                            <Label fontSize={24} fontWeight="bold">Section <span className="text-red-500">*</span></Label>
                            <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <ItemManager
                                    key={`VitalSigns`}
                                    title={ "Vital Signs"}
                                    items={vitalSignsList}
                                    setItems={setVitalSignsList}
                                    setIsPopupOpen={setIsVitalSignsPopupOpen}
                                    customHeight="52"
                                />

                                <ItemManager
                                    key={`BodyMeasurement`}
                                    title={ "Body Measurement"}
                                    items={bodyMeasurementList}
                                    setItems={setBodyMeasurementList}
                                    setIsPopupOpen={setIsBodyMeasurementPopupOpen}
                                    customHeight="52"
                                />

                                <ItemManager
                                    key={`PhysicalActivity`}
                                    title={ "Physical Activity"}
                                    items={physicalActivityList}
                                    setItems={setPhysicalActivityList}
                                    setIsPopupOpen={setIsPhysicalActivityPopupOpen}
                                    customHeight="52"
                                />
                                <ItemManager
                                    key={`GeneralAssessment`}
                                    title={ "General Assessment"}
                                    items={generalAssessmentList}
                                    setItems={setGeneralAssesmentList}
                                    setIsPopupOpen={setIsGeneralAssesmentPopupOpen}
                                    customHeight="52"
                                />
                                <ItemManager
                                    key={`WomenHealth`}
                                    title={"Women Health"}
                                    items={womenHealthList}
                                    setItems={setWomenHealthList}
                                    setIsPopupOpen={setIsWomenHealthPopupOpen}
                                    customHeight="52"
                                />
                                <ItemManager
                                    key={`Lifestyle`}
                                    title={"Lifestyle"}
                                    items={lifestyleList}
                                    setItems={setLifestyleList}
                                    setIsPopupOpen={setIsLifestylePopupOpen}
                                    customHeight="52"
                                />
                                <ItemManager
                                    key={`OtherObservation`}
                                    title={ "Other Observations entry"}
                                    items={otherObservationList}
                                    setItems={setOtherObservationList}
                                    setIsPopupOpen={setIsOtherObservationPopupOpen}
                                    customHeight="52"
                                />
                                <ItemManager
                                    key={`DocumentReference`}
                                    title={ "Document Reference entry"}
                                    items={documentReferenceList}
                                    setItems={setDocumentReferenceList}
                                    setIsPopupOpen={setIsDocumentReferencePopupOpen}
                                    customHeight="52"
                                />
                            </div>
                        </div>
                        {sectionError && (
                            <Label fontSize="sm" fontWeight="bold" color="red-500">
                                {sectionError}
                            </Label>
                        )}
                    </div>
                    
                </div>
                <div className="flex justify-end gap-2">
                    <Button 
                        onClickCb={handleCancle} 
                        variant={BUTTON_VARIANTS.OUTLINED}
                    >Cancel</Button>
                    <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                        {isLinkingFailed ? "Try Again" : "Link to ABDM"}
                    </Button>
                </div>
            </form>
            
            )}
        </Formik>
        {isVitalSignsPopupOpen &&
            <OtherObservation
                title = {"Vital Signs"}
                close={() => setIsVitalSignsPopupOpen(false)}
                onConfirm={setVitalSignsList}
                exampleVal= {{unit: "beats/minute", code: "Heart rate"}}
            />
        }
        {isBodyMeasurementPopupOpen &&
            <OtherObservation
                title = {"Body Measurement"}
                close={() => setIsBodyMeasurementPopupOpen(false)}
                onConfirm={setBodyMeasurementList}
                exampleVal= {{unit: "in", code: "Body height"}}
            />
        }
        {isPhysicalActivityPopupOpen &&
            <OtherObservation
                title = {"Physical Activity"}
                close={() => setIsPhysicalActivityPopupOpen(false)}
                onConfirm={setPhysicalActivityList}
                exampleVal= {{unit: "kcal", code: "Calories burned"}}
            />
        }
        {isGeneralAssesmentPopupOpen &&
            <OtherObservation
                title = {"General Assessment"}
                close={() => setIsGeneralAssesmentPopupOpen(false)}
                onConfirm={setGeneralAssesmentList}
                exampleVal= {{unit: "kg", code: "Body fat mass"}}
            />
        }
        {isWomenHealthPopupOpen &&
            <OtherObservation
                title = {"Women Health"}
                close={() => setIsWomenHealthPopupOpen(false)}
                onConfirm={setWomenHealthList}
                exampleVal= {{unit: "age", code: "Age at menarche"}}
            />
        }
        {isLifestylePopupOpen &&
            <OtherObservation
                title = {"Lifestyle"}
                close={() => setIsLifestylePopupOpen(false)}
                onConfirm={setLifestyleList}
                exampleVal= {{unit: "Vegan diet, Never smoked", code: "Diet, Smoking behavior"}}
                codeable={true}
            />
        }
        {isOtherObservationPopupOpen &&
            <Observation 
                key={`labReport_`}
                onConfirm={setOtherObservationList} 
                close={()=> setIsOtherObservationPopupOpen(false)}
                isNested= {true}
            />
        }
        {isDocumentReferencePopupOpen &&
            <DocumentReference
                open={isDocumentReferencePopupOpen}
                onConfirm={setDocumentReferenceList}
                close={() => setIsDocumentReferencePopupOpen(false)}
            />
        }
        <LoadingPopup
            isOpen={loadingpopup} 
            handleCancle = {() => {
                setLoadingPopup(false)
                handleCancle()
            }}
            handleClose = {() => setLoadingPopup(false)}
        />
        </>
    );
};

export default WellnessRecord;
