import React, { useEffect, useState } from "react";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import { FORM_FIELDS_FOR_OP_CONSULTATION, RECORD_STATUS } from "../Constants";
import { Formik } from "formik";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ItemManager from "../ItemManager";
import { componentKey as PatientDashboardComponentKey, setBundlePushError, setBundlePushResponse, setSelectedBundle } from "../../PatientDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Label from "../../../../../components/common-components/label/Label";
import Encounter from "./Encounter";
import Condition from "./Condition";
import Observation from "./observation";
import FamilyMemberHistory from "./FamilyMemberHistory";
import MedicationRequest from "./MedicationRequest";
import Procedure from "./Procedure";
import DocumentReference from "./DocumentReference";
import Input from "../../../../../components/common-components/input/Input";
import { getValidationSchema } from "../../../../../libs/formsUtils";
import ServiceRequest from "./ServiceRequest";
import Appointment from "./Appointment";
import { createOpConsultationBundle, pushBundle, retryDataPush } from "../../PatientDashboardSaga";
import Heading from "../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../components/common-components/zoom/components/heading/constants/constants";
import HealthRecordHelper from "../utils/utils";
import AllergyIntolerance from "./AlleryIntolerance";
import LoadingPopup from "../LoadingPopup";

const fields = [
	{ fieldName: FORM_FIELDS_FOR_OP_CONSULTATION.STATUS, isRequired: true, isDropdown: true },
    { fieldName: FORM_FIELDS_FOR_OP_CONSULTATION.TITLE, isRequired: true },
    { fieldName: FORM_FIELDS_FOR_OP_CONSULTATION.DATE, isRequired: true },
];

const validationSchema = getValidationSchema(fields);

const OpConsultation = ({ handleCancle, payloadHelper }) => {
    const { patientData, selectedBundle, bundlepushError } = useSelector(
        (state) => state[PatientDashboardComponentKey]
    );
    const [encounterError, setEncounterError] = useState("");
    const [encounterItem, setEncounterItem] = useState([]);
    const [chiefComplaintsList, setChiefComplaintsList] = useState([]);
    const [physicalExaminationList, setPhysicalExaminationList] = useState([]);
    const [allergiesList, setAllergiesList] = useState([]);
    const [medicalHistoryList, setMedicalHistoryList] = useState([]);
    const [familyHistoryList, setFamilyHistoryList] = useState([]);
    const [investigationList, setInvestigationList] = useState([]);
    const [medicationsList, setMedicationsList] = useState([]);
    const [followUpList, setFollowUpList] = useState([]);
    const [proceduresList, setProceduresList] = useState([]);
    const [otherObservationList, setOtherObservationList] = useState([]);
    const [referralList, setReferralList] = useState([]);
    const [documentReferenceList, setDocumentReferenceList] = useState([]);

    const [isEncounterPopupOpen, setIsEncounterPopupOpen] = useState(false);
    const [isConditionPopupOpen, setIsConditionPopupOpen] = useState(false);
    const [isPhysicalExaminationOpen, setIsPhysicalExaminationOpen] = useState(false);
    const [isAllergyPopupOpen, setIsAllergyPopupOpen] = useState(false);
    const [isMedicalHistoryPopupOpen, setIsMedicalHistoryPopupOpen] = useState(false);
    const [isFamilyHistoryPopupOpen, setIsFamilyHistoryPopupOpen] = useState(false);
    const [isInvestigationPopupOpen, setIsInvestigationPopupOpen] = useState(false);
    const [isMedicationsPopupOpen, setIsMedicationsPopupOpen] = useState(false);
    const [isFollowUpPopupOpen, setIsFollowUpPopupOpen] = useState(false);
    const [isProceduresPopupOpen, setIsProceduresPopupOpen] = useState(false);
    const [isOtherObservationPopupOpen, setIsOtherObservationPopupOpen] = useState(false);
    const [isReferralPopupOpen, setIsReferralPopupOpen] = useState(false);
    const [isDocumentReferencePopupOpen, setIsDocumentReferencePopupOpen] = useState(false);
    const [loadingpopup, setLoadingPopup] = useState(false);
    const [isLinkingFailed, setIsLinkingFailed] = useState(false);

    const [sectionError, setSectionError] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        if(chiefComplaintsList.length > 0 || physicalExaminationList.length > 0 || allergiesList.length > 0 || medicalHistoryList.length > 0 || 
            familyHistoryList.length > 0 ||investigationList.length > 0 || medicationsList.length > 0 || followUpList.length > 0 || proceduresList.length > 0 || otherObservationList.length > 0 || referralList.length > 0 || documentReferenceList.length > 0){
                setSectionError("")
        }
        if(encounterItem) setEncounterError("");
    }, [encounterItem, chiefComplaintsList, physicalExaminationList, allergiesList, medicalHistoryList, 
        familyHistoryList,investigationList, medicationsList, followUpList, proceduresList, otherObservationList, referralList, documentReferenceList])

    useEffect(() => {
        if(selectedBundle){
            const payload = {
                ...payloadHelper,
                patientId: patientData?.uuid,
                bundleData: selectedBundle,
            }
            if(isLinkingFailed) {
                payload.previous = true;
                dispatch(retryDataPush(payload))
            } else {
                dispatch(pushBundle(payload));
            }
            setLoadingPopup(true)
        }
    },[dispatch, selectedBundle])

    useEffect(() => {
        if(bundlepushError?.statusCode){
        setLoadingPopup(false);
        setIsLinkingFailed(true)
        dispatch(setBundlePushError({}))
        }
    }, [bundlepushError, dispatch])

    useEffect(() => {
        return () => {
            dispatch(setSelectedBundle(null))
            dispatch(setBundlePushResponse({}))
            dispatch(setBundlePushError({}))
        }
    }, [dispatch])

    const structurePayloadWithAllData = (values) => {
        const payload = {
            title: values[FORM_FIELDS_FOR_OP_CONSULTATION.TITLE],
            status: values[FORM_FIELDS_FOR_OP_CONSULTATION.STATUS].value,
            date: values[FORM_FIELDS_FOR_OP_CONSULTATION.DATE],
            subject: HealthRecordHelper.getStructurePatientData(patientData),
            encounter: encounterItem[0]?.data,
            section: [
                chiefComplaintsList.length > 0 ? {
                    chiefComplaintsEntry: chiefComplaintsList.map((item) => item.data),
                } : null,
                physicalExaminationList.length > 0 ? {
                    physicalExaminationEntry: physicalExaminationList.map((item) => item.data),
                } : null,
                allergiesList.length > 0 ? {
                    allergiesEntry: allergiesList.map((item) => item.data),
                } : null,
                medicalHistoryList.length > 0 ? {
                    medicalHistoryEntry: medicalHistoryList.map((item) => item.data),
                } : null,
                familyHistoryList.length > 0 ? {
                    familyHistoryEntry: familyHistoryList.map((item) => item.data),
                } : null,
                investigationList.length > 0 ? {
                    investigationEntry: investigationList.map((item) => item.data),
                } : null,
                medicationsList.length > 0 ? {
                    medicationsEntry: medicationsList.map((item) => item.medicationRequestReport),
                } : null,
                followUpList.length > 0 ? {
                    followUpEntry: followUpList.map((item) => item.data),
                } : null,
                proceduresList.length > 0 ? {
                    proceduresEntry: proceduresList.map((item) => item.data),
                } : null,
                referralList.length > 0 ? {
                    referralEntry: referralList.map((item) => item.data),
                } : null,
                otherObservationList.length > 0 ? {
                    otherObservationsEntry : otherObservationList.map((item) => item.data)
                } : null,
                documentReferenceList.length > 0 ? {
                    documentReferenceEntry: documentReferenceList.map((item) => item.data),
                } : null,
            ].filter(Boolean),
        };
        return payload;
    };

    return (
        <>
        <Formik
            initialValues={{
                [FORM_FIELDS_FOR_OP_CONSULTATION.STATUS]: "",
                [FORM_FIELDS_FOR_OP_CONSULTATION.ENCOUNTER]:"",
                [FORM_FIELDS_FOR_OP_CONSULTATION.DATE]:"",
                [FORM_FIELDS_FOR_OP_CONSULTATION.TITLE]:"",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                dispatch(setSelectedBundle(null));
                if (encounterItem.length === 0) {
                    setEncounterError("Encounter entry is required.");
                    return;
                }
                const payload = structurePayloadWithAllData(values);
                if(payload.section.length <= 0) {
                    setSectionError("Atleast 1 section is required")
                    return;
                }
                dispatch(createOpConsultationBundle(payload));
            }}
        >
            {({ values, errors, setFieldValue, handleSubmit }) => (
                <>
                    <form
                        onSubmit={handleSubmit}
                        className="w-full flex-1 flex flex-col gap-2 justify-between"
                    >
                        <div className="h-full flex flex-col gap-2 ">
                            <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
                                <SelectDropdown
                                    label="Composition Status"
                                    name={FORM_FIELDS_FOR_OP_CONSULTATION.STATUS}
                                    isRequired={true}
                                    placeholder="Select"
                                    value={values[FORM_FIELDS_FOR_OP_CONSULTATION.STATUS]}
                                    options={RECORD_STATUS}
                                    onChangeCb={(selectedOption) =>
                                        setFieldValue(FORM_FIELDS_FOR_OP_CONSULTATION.STATUS, selectedOption)
                                    }
                                />
                                <Input
                                    label="Title"
                                    placeholder="Enter the Title"
                                    isRequired={true}
                                    name={FORM_FIELDS_FOR_OP_CONSULTATION.TITLE}
                                    value={values[FORM_FIELDS_FOR_OP_CONSULTATION.TITLE]}
                                    onChangeCb={(e) => {
                                        setFieldValue(FORM_FIELDS_FOR_OP_CONSULTATION.TITLE, e.target.value)
                                    }}
                                />

                                <Input
                                    label="Date"
                                    name={FORM_FIELDS_FOR_OP_CONSULTATION.DATE}
                                    type="datetime-local"
                                    isRequired={true}
                                    value={values[FORM_FIELDS_FOR_OP_CONSULTATION.DATE]}
                                    onChangeCb={(e) => setFieldValue(FORM_FIELDS_FOR_OP_CONSULTATION.DATE, e.target.value)}
                                />
                            </div>

                            <div className="flex flex-col">
                                <ItemManager
                                    key={`op_encounter_entry_opconsult`}
                                    title={
                                        <>
                                        Encounter entry <span className="text-red-500">*</span>
                                        </>
                                    }
                                    items={encounterItem}
                                    setItems={setEncounterItem}
                                    setIsPopupOpen={(value) => {
                                        if(encounterItem.length < 1){
                                            setIsEncounterPopupOpen(value)
                                        }else setEncounterError("Only one encounter is allowed")
                                        
                                    }}
                                    customHeight="52"
                                />
                                {encounterError && (
                                    <Label fontSize="sm" fontWeight="bold" color="red-500">
                                        {encounterError}
                                    </Label>
                                )}
                            </div>
                            <div>
                                <Heading type={HEADING.H2}> Section <span className="text-red-500">*</span></Heading>
                                <div className="p-2 border-2 border-gray-200 rounded-lg max-h-[15vh] overflow-y-auto">
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        <ItemManager
                                            key={`chieifCompaints_opconsult`}
                                            title={ "Chief Complaints entry"}
                                            items={chiefComplaintsList}
                                            setItems={setChiefComplaintsList}
                                            setIsPopupOpen={setIsConditionPopupOpen}
                                            customHeight="52"
                                        />
                                        <ItemManager
                                            key={`physicalExamination_opconsult`}
                                            title={ "Physical Examination entry"}
                                            items={physicalExaminationList}
                                            setItems={setPhysicalExaminationList}
                                            setIsPopupOpen={setIsPhysicalExaminationOpen}
                                            customHeight="52"
                                        />
                                        <ItemManager
                                            key={`allergies_opconsult`}
                                            title={ "Allergies entry"}
                                            items={allergiesList}
                                            setItems={setAllergiesList}
                                            setIsPopupOpen={setIsAllergyPopupOpen}
                                            customHeight="52"
                                        />
                                        <ItemManager
                                            key={`MedicalHistory_opconsult`}
                                            title={ "Medical History entry"}
                                            items={medicalHistoryList}
                                            setItems={setMedicalHistoryList}
                                            setIsPopupOpen={setIsMedicalHistoryPopupOpen}
                                            customHeight="52"
                                        />
                                        <ItemManager
                                            key={`FamilyHistory_opconsult`}
                                            title={ "Family Member History entry"}
                                            items={familyHistoryList}
                                            setItems={setFamilyHistoryList}
                                            setIsPopupOpen={setIsFamilyHistoryPopupOpen}
                                            customHeight="52"
                                        />
                                        <ItemManager
                                            key={`InvestigationAdvice_opconsult`}
                                            title={ "Investigation Advice entry"}
                                            items={investigationList}
                                            setItems={setInvestigationList}
                                            setIsPopupOpen={setIsInvestigationPopupOpen}
                                            customHeight="52"
                                        />
                                        <ItemManager
                                            key={`Medications_opconsult`}
                                            title={ "Medications entry"}
                                            items={medicationsList}
                                            setItems={setMedicationsList}
                                            setIsPopupOpen={setIsMedicationsPopupOpen}
                                            customHeight="52"
                                        />
                                        <ItemManager
                                            key={`FollowUp_opconsult`}
                                            title={ "Follow Up entry"}
                                            items={followUpList}
                                            setItems={setFollowUpList}
                                            setIsPopupOpen={setIsFollowUpPopupOpen}
                                            customHeight="52"
                                        />
                                        <ItemManager
                                            key={`Procedures_opconsult`}
                                            title={ "Procedures entry"}
                                            items={proceduresList}
                                            setItems={setProceduresList}
                                            setIsPopupOpen={setIsProceduresPopupOpen}
                                            customHeight="52"

                                        />
                                        <ItemManager
                                            key={`Referral_opconsult`}
                                            title={ "Referral entry"}
                                            items={referralList}
                                            setItems={setReferralList}
                                            setIsPopupOpen={setIsReferralPopupOpen}
                                            customHeight="52"
                                        />
                                        <ItemManager
                                            key={`OtherObservation_opconsult`}
                                            title={ "Other Observations entry"}
                                            items={otherObservationList}
                                            setItems={setOtherObservationList}
                                            setIsPopupOpen={setIsOtherObservationPopupOpen}
                                            customHeight="52"
                                        />
                                        <ItemManager
                                            key={`DocumentReference	_opconsult`}
                                            title={ "Document Reference	 entry"}
                                            items={documentReferenceList}
                                            setItems={setDocumentReferenceList}
                                            setIsPopupOpen={setIsDocumentReferencePopupOpen}
                                            customHeight="52"
                                        />
                                    </div>
                                </div>
                                {sectionError && (
                                    <Label fontSize="sm" fontWeight="bold" color="red-500">
                                        {sectionError}
                                    </Label>
                                )}
                            </div>
                        </div>
                        <div className="flex justify-end gap-2">
                            <Button 
                                onClickCb={handleCancle} 
                                variant={BUTTON_VARIANTS.OUTLINED}
                            >Cancel</Button>
                            <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                {isLinkingFailed ? "Try Again" : "Link to ABDM"}
                            </Button>
                        </div>
                    </form>
                </>
            
            )}
        </Formik>
        {isEncounterPopupOpen && 
            <Encounter
                open={isEncounterPopupOpen}
                onConfirm={setEncounterItem}
                close={() => setIsEncounterPopupOpen(false)}
            />
        }
        {isConditionPopupOpen &&
            <Condition
                title={"Condition"}
                value="Identification of the condition, problem or diagnosis"
                open={isConditionPopupOpen}
                onConfirm={setChiefComplaintsList}
                close={() => setIsConditionPopupOpen(false)}
            />
        }
        {isAllergyPopupOpen &&
            <AllergyIntolerance
                open={isAllergyPopupOpen}
                onConfirm={setAllergiesList}
                close={() => setIsAllergyPopupOpen(false)}
            />
        }
        {isPhysicalExaminationOpen &&
            <Observation 
                key={`labReport_${uuidv4()}`}
                onConfirm={setPhysicalExaminationList} 
                close={()=> setIsPhysicalExaminationOpen(false)}
                isNested= {true}
            />
        }
        {isMedicalHistoryPopupOpen &&
            <Condition
                title={"Condition"}
                value="Identification of the condition, problem or diagnosis"
                open={isMedicalHistoryPopupOpen}
                onConfirm={setMedicalHistoryList}
                close={() => setIsMedicalHistoryPopupOpen(false)}
            />
        }
        {isFamilyHistoryPopupOpen &&
            <FamilyMemberHistory
                open={isFamilyHistoryPopupOpen}
                onConfirm={setFamilyHistoryList}
                close={() => setIsFamilyHistoryPopupOpen(false)}
            />
        }
        {isInvestigationPopupOpen && 
            <ServiceRequest
                open={isInvestigationPopupOpen}
                onConfirm={setInvestigationList}
                close={() => setIsInvestigationPopupOpen(false)}
            />
        }
        {isMedicationsPopupOpen && 
            <MedicationRequest
                onConfirm={setMedicationsList} 
                close={() => setIsMedicationsPopupOpen(false)}
            />
        }
        {isFollowUpPopupOpen && 
            <Appointment
                open={isFollowUpPopupOpen}    
                onConfirm={setFollowUpList}        
                close={() => setIsFollowUpPopupOpen(false)}
            />
        }
        {isProceduresPopupOpen &&
            <Procedure
                open={isProceduresPopupOpen}
                onConfirm={setProceduresList}
                close={() => setIsProceduresPopupOpen(false)}
            />
        }
        {isReferralPopupOpen && 
            <ServiceRequest
                open={isReferralPopupOpen}
                onConfirm={setReferralList}
                close={() => setIsReferralPopupOpen(false)}
            />
        }
        {isOtherObservationPopupOpen &&
            <Observation 
                key={`labReport_${uuidv4()}`}
                onConfirm={setOtherObservationList} 
                close={()=> setIsOtherObservationPopupOpen(false)}
            />
        }
        {isDocumentReferencePopupOpen &&
            <DocumentReference
                open={isDocumentReferencePopupOpen}
                onConfirm={setDocumentReferenceList}
                close={() => setIsDocumentReferencePopupOpen(false)}
            />
        }
        <LoadingPopup
            isOpen={loadingpopup} 
            handleCancle = {() => {
                setLoadingPopup(false)
                handleCancle()
            }}
            handleClose = {() => setLoadingPopup(false)}
        />
        </>
    );
};

export default OpConsultation;
