import React, { useEffect, useState } from "react";
import SelectDropdown from "../../../../../components/common-components/selectDropdown";
import {  RECORD_STATUS } from "../Constants";
import { Formik } from "formik";
import Button from "../../../../../components/common-components/button/Button";
import { BUTTON_VARIANTS } from "../../../../../components/common-components/button/Constants";
import ItemManager from "../ItemManager";
import { componentKey as PatientDashboardComponentKey, setBundlePushError, setBundlePushResponse, setIscareContextLinked, setSelectedBundle } from "../../PatientDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { createDischargeSummaryBundle, pushBundle, retryDataPush } from "../../PatientDashboardSaga";
import Label from "../../../../../components/common-components/label/Label";

import Encounter from "./Encounter";
import Condition from "./Condition";
import Observation from "./observation";
import FamilyMemberHistory from "./FamilyMemberHistory";
import DiagnosticLabReport from "./diagnosticLabReport";
import MedicationRequest from "./MedicationRequest";
import Procedure from "./Procedure";
import CarePlan from "./CarePlan";
import DocumentReference from "./DocumentReference";
import Heading from "../../../../../components/common-components/zoom/components/heading/Heading";
import { HEADING } from "../../../../../components/common-components/zoom/components/heading/constants/constants";
import HealthRecordHelper from "../utils/utils";
import AllergyIntolerance from "./AlleryIntolerance";
import LoadingPopup from "../LoadingPopup";

const DischargeSummary = ({ handleCancle, payloadHelper }) => {
    const { patientData, selectedBundle, bundlepushError } = useSelector(
        (state) => state[PatientDashboardComponentKey]
    );
    const [encounterError, setEncounterError] = useState("");
    const [encounterItem, setEncounterItem] = useState([]);
    const [chiefComplaintsList, setChiefComplaintsList] = useState([]);
    const [physicalExaminationList, setPhysicalExaminationList] = useState([]);
    const [allergiesList, setAllergiesList] = useState([]);
    const [medicalHistoryList, setMedicalHistoryList] = useState([]);
    const [familyHistoryList, setFamilyHistoryList] = useState([]);
    const [investigationList, setInvestigationList] = useState([]);
    const [medicationsList, setMedicationsList] = useState([]);
    const [proceduresList, setProceduresList] = useState([]);
    const [carePlanList, setCarePlanList] = useState([]);
    const [documentReferenceList, setDocumentReferenceList] = useState([]);

    const [isEncounterPopupOpen, setIsEncounterPopupOpen] = useState(false);
    const [isConditionPopupOpen, setIsConditionPopupOpen] = useState(false);
    const [isPhysicalExaminationOpen, setIsPhysicalExaminationOpen] = useState(false);
    const [isAllergyPopupOpen, setIsAllergyPopupOpen] = useState(false);
    const [isMedicalHistoryPopupOpen, setIsMedicalHistoryPopupOpen] = useState(false);
    const [isFamilyHistoryPopupOpen, setIsFamilyHistoryPopupOpen] = useState(false);
    const [isInvestigationPopupOpen, setIsInvestigationPopupOpen] = useState(false);
    const [isMedicationsPopupOpen, setIsMedicationsPopupOpen] = useState(false);
    const [isProceduresPopupOpen, setIsProceduresPopupOpen] = useState(false);
    const [isCarePlanPopupOpen, setIsCarePlanPopupOpen] = useState(false);
    const [isDocumentReferencePopupOpen, setIsDocumentReferencePopupOpen] = useState(false);
    const [loadingpopup, setLoadingPopup] = useState(false);
    const [isLinkingFailed, setIsLinkingFailed] = useState(false);
    const [sectionError, setSectionError] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        if(encounterItem){
            setEncounterError("");
        }
        if(chiefComplaintsList || physicalExaminationList ||allergiesList || medicalHistoryList || familyHistoryList || investigationList || medicationsList || proceduresList || carePlanList || documentReferenceList){
            setSectionError("");
        }
    }, [encounterItem, chiefComplaintsList, physicalExaminationList,allergiesList, medicalHistoryList, familyHistoryList, investigationList, medicationsList, proceduresList, carePlanList, documentReferenceList])

    const validate = (values) => {
        const errors = {};
        if (!values.compositionStatus || values.compositionStatus.value === "Select Status") {
            errors.compositionStatus = "Please select a composition status.";
        }
        return errors;
    };

    useEffect(() => {
        if(selectedBundle){
            const payload = {
                ...payloadHelper,
                patientId: patientData?.uuid,
                bundleData: selectedBundle,
            }
            if(isLinkingFailed) {
                payload.previous = true;
                dispatch(retryDataPush(payload))
            } else {
                dispatch(pushBundle(payload));
            }
            setLoadingPopup(true);
        }
    },[dispatch, selectedBundle])

  useEffect(() => {
    if(bundlepushError?.statusCode){
      setLoadingPopup(false);
      setIsLinkingFailed(true)
      dispatch(setBundlePushError({}))
    }
  }, [bundlepushError, dispatch])

    const structurePayloadWithAllData = (values) => {
        const payload = {
            title: "DischargeSummary",
            status: values.compositionStatus.value,
            subject: HealthRecordHelper.getStructurePatientData(patientData),
            encounter: encounterItem[0]?.data,
            section: [
                chiefComplaintsList.length > 0 ? {
                    chiefComplaintsEntry: chiefComplaintsList.map((item) => item.data),
                } : null,
                physicalExaminationList.length > 0 ? {
                    physicalExaminationEntry: physicalExaminationList.map((item) => item.data),
                } : null,
                allergiesList.length > 0 ? {
                    allergiesEntry: allergiesList.map((item) => item.data),
                } : null,
                medicalHistoryList.length > 0 ? {
                    medicalHistoryEntry: medicalHistoryList.map((item) => item.data),
                } : null,
                familyHistoryList.length > 0 ? {
                    familyHistoryEntry: familyHistoryList.map((item) => item.data),
                } : null,
                investigationList.length > 0 ? {
                    investigationEntry: investigationList.map((item) => item.labReport),
                } : null,
                medicationsList.length > 0 ? {
                    medicationsEntry: medicationsList.map((item) => item.medicationRequestReport),
                } : null,
                proceduresList.length > 0 ? {
                    proceduresEntry: proceduresList.map((item) => item.data),
                } : null,
                carePlanList.length > 0 ? {
                    carePlanEntry: carePlanList.map((item) => item.data),
                } : null,
                documentReferenceList.length > 0 ? {
                    documentReferenceEntry: documentReferenceList.map((item) => item.data),
                } : null,
            ].filter(Boolean),
        };
        return payload;
    };

    useEffect(() => {
        return () => {
            dispatch(setSelectedBundle(null))
            dispatch(setBundlePushResponse({}))
            dispatch(setBundlePushError({}))
        }
    }, [dispatch])

    return (
        <>
        <Formik
            initialValues={{
                compositionStatus: { label: "Select Status", value: "Select Status" },
            }}
            validate={validate}
            onSubmit={(values) => {
                dispatch(setSelectedBundle(null));
                if (encounterItem.length === 0) {
                    setEncounterError("Encounter entry is required.");
                    return;
                }
                const payload = structurePayloadWithAllData(values);
                if(payload.section.length <= 0) {
                    setSectionError("Atleast 1 section is required")
                    return;
                }
                dispatch(createDischargeSummaryBundle(payload));
            }}
        >
            {({ values, errors, setFieldValue, handleSubmit }) => (
                <>
                    <form
                        onSubmit={handleSubmit}
                        className="w-full h-full flex-1 flex flex-col gap-2 justify-between"
                    >
                        <div className=" h-[44vh] w-full flex flex-col justify-between overflow-y-auto">
                            <div className="flex h-full flex-col gap-2">
                                <SelectDropdown
                                    label="Composition Status"
                                    name="compositionStatus"
                                    isRequired={true}
                                    customClasses="w-1/2"
                                    value={values.compositionStatus}
                                    options={[{ label: "Select Status", value: "Select Status" }, ...RECORD_STATUS]}
                                    onChangeCb={(selectedOption) =>
                                        setFieldValue("compositionStatus", selectedOption)
                                    }
                                />
                                <div className="flex flex-col">
                                    <ItemManager
                                        key={`encounterEntry`}
                                        title={
                                            <>
                                            Encounter entry <span className="text-red-500">*</span>
                                            </>
                                        }
                                        items={encounterItem}
                                        setItems={setEncounterItem}
                                        setIsPopupOpen={(value) => {
                                            if(encounterItem.length < 1){
                                                setIsEncounterPopupOpen(value)
                                            }else setEncounterError("Only one encounter is allowed")
                                            
                                        }}
                                        customHeight="52"
                                    />
                                    {encounterError && (
                                        <Label fontSize="sm" fontWeight="bold" color="red-500">
                                            {encounterError}
                                        </Label>
                                    )}
                                </div>
                                <div className="flex-1">
                                    <div>
                                        <Heading type={HEADING.H2}> Section <span className="text-red-500">*</span></Heading>
                                        <div className="p-1 border-2 border-gray-200 rounded-lg max-h-[15vh] overflow-y-auto">
                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                                                <ItemManager
                                                    key={`chieifCompaints_`}
                                                    title={ "Chief Complaints entry"}
                                                    items={chiefComplaintsList}
                                                    setItems={setChiefComplaintsList}
                                                    setIsPopupOpen={setIsConditionPopupOpen}
                                                    customHeight="52"
                                                />
                                                <ItemManager
                                                    key={`physicalExamination`}
                                                    title={ "Physical Examination entry"}
                                                    items={physicalExaminationList}
                                                    setItems={setPhysicalExaminationList}
                                                    setIsPopupOpen={setIsPhysicalExaminationOpen}
                                                    customHeight="52"
                                                />
                                                <ItemManager
                                                    key={`allergies`}
                                                    title={ "Allergies entry"}
                                                    items={allergiesList}
                                                    setItems={setAllergiesList}
                                                    setIsPopupOpen={setIsAllergyPopupOpen}
                                                    customHeight="52"
                                                />
                                                <ItemManager
                                                    key={`MedicalHistory`}
                                                    title={ "Medical History entry"}
                                                    items={medicalHistoryList}
                                                    setItems={setMedicalHistoryList}
                                                    setIsPopupOpen={setIsMedicalHistoryPopupOpen}
                                                    customHeight="52"
                                                />
                                                <ItemManager
                                                    key={`FamilyHistory`}
                                                    title={ "Family Member History entry"}
                                                    items={familyHistoryList}
                                                    setItems={setFamilyHistoryList}
                                                    setIsPopupOpen={setIsFamilyHistoryPopupOpen}
                                                    customHeight="52"
                                                />
                                                <ItemManager
                                                    key={`Investigations`}
                                                    title={ "Investigations entry"}
                                                    items={investigationList}
                                                    setItems={setInvestigationList}
                                                    setIsPopupOpen={setIsInvestigationPopupOpen}
                                                    customHeight="52"
                                                />
                                                <ItemManager
                                                    key={`Medications`}
                                                    title={ "Medications entry"}
                                                    items={medicationsList}
                                                    setItems={setMedicationsList}
                                                    setIsPopupOpen={setIsMedicationsPopupOpen}
                                                    customHeight="52"
                                                />
                                                <ItemManager
                                                    key={`Procedures`}
                                                    title={ "Procedures entry"}
                                                    items={proceduresList}
                                                    setItems={setProceduresList}
                                                    setIsPopupOpen={setIsProceduresPopupOpen}
                                                    customHeight="52"
                                                />
                                                <ItemManager
                                                    key={`CarePlan`}
                                                    title={ "Care Plan entry"}
                                                    items={carePlanList}
                                                    setItems={setCarePlanList}
                                                    setIsPopupOpen={setIsCarePlanPopupOpen}
                                                    customHeight="52"
                                                />
                                                <ItemManager
                                                    key={`DocumentReference	`}
                                                    title={ "Document Reference	 entry"}
                                                    items={documentReferenceList}
                                                    setItems={setDocumentReferenceList}
                                                    setIsPopupOpen={setIsDocumentReferencePopupOpen}
                                                    customHeight="52"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {sectionError && (
                                        <Label fontSize="sm" fontWeight="bold" color="red-500">
                                            {sectionError}
                                        </Label>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end gap-2">
                            <Button 
                                onClickCb={handleCancle} 
                                variant={BUTTON_VARIANTS.OUTLINED}
                            >Cancel</Button>
                            <Button type="submit" variant={BUTTON_VARIANTS.CONTAINED} customBtnClass="px-6 py-2">
                                {isLinkingFailed ? "Try Again" : "Link to ABDM"}
                            </Button>
                        </div>
                    </form>
                </>
            
            )}
        </Formik>
        {isEncounterPopupOpen && 
            <Encounter
                open={isEncounterPopupOpen}
                onConfirm={setEncounterItem}
                close={() => setIsEncounterPopupOpen(false)}
            />
        }
        {isConditionPopupOpen &&
            <Condition
                title={"Condition"}
                value="Identification of the condition, problem or diagnosis"
                open={isConditionPopupOpen}
                onConfirm={setChiefComplaintsList}
                close={() => setIsConditionPopupOpen(false)}
            />
        }
        {isAllergyPopupOpen &&
            <AllergyIntolerance
                open={isAllergyPopupOpen}
                onConfirm={setAllergiesList}
                close={() => setIsAllergyPopupOpen(false)}
            />
        }
        {isPhysicalExaminationOpen &&
            <Observation 
                key={`labReport_`}
                onConfirm={setPhysicalExaminationList} 
                close={()=> setIsPhysicalExaminationOpen(false)}
                isNested= {true}
            />
        }
        {isMedicalHistoryPopupOpen &&
            <Condition
                title={"Condition"}
                value="Identification of the condition, problem or diagnosis"
                open={isMedicalHistoryPopupOpen}
                onConfirm={setMedicalHistoryList}
                close={() => setIsMedicalHistoryPopupOpen(false)}
            />
        }
        {isFamilyHistoryPopupOpen &&
            <FamilyMemberHistory
                open={isFamilyHistoryPopupOpen}
                onConfirm={setFamilyHistoryList}
                close={() => setIsFamilyHistoryPopupOpen(false)}
            />
        }
        {isInvestigationPopupOpen && 
            <DiagnosticLabReport
                onConfirm={setInvestigationList} 
                close={() => setIsInvestigationPopupOpen(false)}
            />
        }
        {isMedicationsPopupOpen && 
            <MedicationRequest
                onConfirm={setMedicationsList} 
                close={() => setIsMedicationsPopupOpen(false)}
            />
        }
        {isProceduresPopupOpen &&
            <Procedure
                open={isProceduresPopupOpen}
                onConfirm={setProceduresList}
                close={() => setIsProceduresPopupOpen(false)}
            />
        }
        {isCarePlanPopupOpen &&
            <CarePlan
                open={isCarePlanPopupOpen}
                onConfirm={setCarePlanList}
                close={() => setIsCarePlanPopupOpen(false)}
            />
        }
        {isDocumentReferencePopupOpen &&
            <DocumentReference
                open={isDocumentReferencePopupOpen}
                onConfirm={setDocumentReferenceList}
                close={() => setIsDocumentReferencePopupOpen(false)}
            />
        }
        <LoadingPopup
            isOpen={loadingpopup} 
            handleCancle = {() => {
                setLoadingPopup(false)
                handleCancle()
            }}
            handleClose = {() => setLoadingPopup(false)}
        />
        </>
    );
};

export default DischargeSummary;
